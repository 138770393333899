<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <v-layout align-center justify-center>
      <v-flex xs12 lg6>
        <v-card>
          <v-card-title class="flex">
            <h3>Choose An Account</h3>
          </v-card-title>
          <v-card-text class="account-list">
            <v-list-tile
              v-for="(proxy, i) in actAsOwnerList"
              :key="i"
              class="v-list-item account-list__account"
              :class="{
                active: proxy.actAsUsername === actAsOwner
              }"
              @click="actAsOwner = proxy.actAsUsername"
            >
              <v-list-tile-action>
                <v-icon>account_circle</v-icon>
              </v-list-tile-action>
              <v-list-tile-title class="mx-2">
                <div class="account-list__account__title">
                  <h4>{{ proxy.name || proxy.email }}</h4>
                  <span v-if="proxy.name">{{ proxy.email }}</span>
                </div>
                <div class="account-list__account__access">
                  <span>{{ proxy.access }}</span>
                </div>
              </v-list-tile-title>
              <v-list-tile-action class="account-list__account__actions">
                <!--
                  Not showing edit button for logged in account
                  Hide edit button until nickname API ready
                -->
                <!-- <v-btn
                  v-if="proxy.actAsUsername !== null"
                  @click.stop="editNickname(proxy)"
                  class="p-0 border-none"
                >
                  <v-icon size="18">edit</v-icon>
                </v-btn> -->
                <v-icon large>keyboard_arrow_right</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <ChangeNickname
      v-model="changeNickname.show"
      :selected="changeNickname.selected"
      @done="changeNickname.selected = null"
    />
  </v-container>
</template>

<script>
import { SortHelpers, OwnerProxyHelpers } from '@/library/helpers';
import { mapState } from 'vuex';
import ChangeNickname from '@/components/modals/ChangeNickname.vue';

export default {
  name: "AccountSelection",
  components: {
    ChangeNickname
  },
  data() {
    return {
      changeNickname: {
        show: false,
        selected: null
      }
    }
  },
  computed: {
    ...mapState({
      canActAsUsers: state => state.OwnerProxy.canActAsUsers
    }),
    actAsOwner: {
      get() {
        return this.$store.state.OwnerProxy.actAs
      },
      set(v) {
        this.$store.dispatch("OwnerProxy/setActAs", v);
        this.$store.dispatch("initialize")
        this.$router.push({ name: "owner-dashboard" })
      }
    },
    actAsOwnerList() {
      return [
        {
          actAsUsername: null,
          name: "My Account",
          email: localStorage.email,
          access: "Full Access"
        },
        ...this.canActAsUsers
          .map(p => ({
            actAsUsername: p.owner_username,
            name: p.nickname,
            email: p.owner_username,
            forSorting: p.nickname || p.owner_username,
            access: `${OwnerProxyHelpers.getRightsText(p.rights)} Access`
          }))
          .sort(SortHelpers.compareValues("forSorting"))
      ]
    }
  },
  methods: {
    editNickname(selected) {
      this.changeNickname.show = true
      this.changeNickname.selected = selected
    }
  },
  mounted() {
    if (this.actAsOwnerList.length <= 1) {
      this.$router.push({ name: "owner-dashboard" })
    }
  }
}
</script>

<style lang="scss">
.theme--dark {
  .account-list {
    &__account {
      &:hover {
        background: lighten($color: #303030, $amount: 20);
      }
    }
  }

}
.account-list {
  padding: 0;
  *:not(span, i, h4, button) {
    height: 5rem;
  }
  &__account {
    border-top: 1px solid #e0e0e0;
    padding: 0 0.5rem;
    &:hover {
      cursor: pointer;
      background: #f8f8f8;
    }
    &__title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start!important;
    }
    &__actions {
      width: 8rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end!important;
      align-items: center;
      .v-btn {
        background-color: transparent!important;
        border: none!important;
        min-width: 3rem;
        box-shadow: none!important;
      }
    }
    .v-icon {
      font-size: 3.5rem;
    }
    .v-list__tile {
      padding: 0;
      &__action {
        display: flex;
        justify-content: center;
      }
      &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        * {
          display: flex;
          align-items: center;
        }
      }
    }
    &.active {
      border-left: 0.3rem solid #f09000;
    }
  }
}
</style>