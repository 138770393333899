<template>
  <v-container column fluid :class="[loading ? `fill-height text-xs-center full-inner-height` : '']">
    <v-layout v-if="!loading" row wrap align-center class="mb-4 pb-4">
      <v-flex xs12 align-center>
        <h2 class="d-inline-block font-weight-medium"><span class="hidden-sm-and-down">Device Management &gt; Network Configuration &gt; </span>Connect Via Bluetooth</h2>
        <v-btn small dark color="green" class="plugzio-button create-button mx-0 mb-0 ml-3" round outline @click="$router.push({ path: 'device-network-management' })">
          BACK
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout align-center justify-center>
      <v-flex xs12 lg6 md9 v-if="!!deviceDisconnected" class="flex flex-column items-center">
        <v-layout xs12 align-center>
          <v-flex xs12 v-if="deviceDisconnected == 'unintentional'">
            <v-card>
              <v-card-title class="flex justify-center">
                <h3>You Were Disconnected</h3>
              </v-card-title>
              <div>
                <v-card-text>
                  <ul class="text-xs-left">
                    <li>Make sure that device is registered to your account under the <strong>Device Management</strong> page.</li>
                    <li class="mt-2">Make sure you are standing at close proximity to the device you want to connect to.</li>
                    <li class="mt-2">Make sure Plugzio device is powered on.</li>
                    <li class="mt-2">
                      Make sure the device is not connected to the internet - Plugzio devices only enable their bluetooth when there is no internet (i.e. breathing red LED). To
                      check if the device is connected to the internet, try registering or accessing it from the <strong>Dashboard</strong>.
                    </li>
                    <li class="mt-2">Contact <strong>Plugzio Support</strong> and we can assist you!</li>
                  </ul>
                </v-card-text>

                <v-card-actions class="pa-3 flex justify-end">
                  <v-btn color="green" flat @click="deviceDisconnected = null">Retry</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-flex>
          <v-flex xs12 v-if="deviceDisconnected == 'intentional'">
            <v-card>
              <v-card-title class="flex justify-center">
                <h3 class="green--text text--lighten-1">{{ `Plugzio-${disconnectedDeviceId}` }} is now connected to the Cloud!</h3>
              </v-card-title>
              <div>
                <v-card-text>
                  <p class="text-xs-left">
                    Your Plugzio device just connected to the Cloud. You can now configure your device through the
                    <strong class="green--text text--lighten-1">Dashboard.</strong>
                  </p>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn
                    color="green"
                    flat
                    round
                    @click="
                      deviceDisconnected = null;
                      disconnectedDeviceId = null;
                    "
                    >CONNECT TO ANOTHER DEVICE</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat round @click="$router.push({ path: '/owner/outlet-management' })">GO TO DASHBOARD</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm9 md6 lg4 v-else-if="!!loading || !!error">
        <v-flex v-if="!!loading" id="loadingContainer" class="flex flex-column items-center justify-center">
          <v-progress-circular class="loading-circle" size="40" indeterminate color="black" />
          <div class="mt-4">
            <h2>{{ loading }}</h2>
          </div>
        </v-flex>
        <v-flex v-else-if="!!error" class="flex flex-column items-center justify-center">
          <v-card>
            <v-card-title class="flex justify-center">
              <h3 v-if="!error.includes('You are attempting to pair to a new device with Device ID')" class="red--text text--lighten-1">
                Error
              </h3>
              <h3 v-else class="flex xs12 text-xs-left xs12 text--lighten-1">Pairing To A New Device</h3>
            </v-card-title>
            <div>
              <v-card-text>
                <p class="text-xs-left">
                  {{ error }}
                </p>
              </v-card-text>
              <v-card-actions class="pa-3 flex flex-wrap">
                <v-btn
                  color="gray"
                  flat="flat"
                  @click="
                    loading = null;
                    error = null;
                    deviceId = null;
                    menu = 'main';
                  "
                  >BACK</v-btn
                >
                <template v-if="bleConnection.forceConnectManually">
                  <v-spacer></v-spacer>
                  <v-btn justify-end class="plugzio-button" outline round @click="connectBleDevice()" :loading="!!loading">CONNECT MANUALLY</v-btn>
                </template>
              </v-card-actions>
            </div>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 fill-height sm9 class="pa-2" v-else-if="ble.connectedDevice">
        <v-layout justify-space-between column>
          <v-flex xs12>
            <v-layout xs12 justify-center>
              <v-flex xs12 sm8 md6 class="bluetoothconnected">
                <div class="bluetoothconnected__icon">
                  <v-icon>bluetooth</v-icon>
                </div>
                <span class="bluetoothconnected__text">
                  Paired To: <b>{{ `Plugzio-${ble.connectedDeviceId}` }}</b>
                </span>
                <v-icon
                  class="tooltip-icon ml-2"
                  v-tooltip="{
                    content: 'To maintain Bluetooth connection, please remain within close proximity to the device. Moving out of range will cause Bluetooth to disconnect.',
                    placement: 'top-center',
                    trigger: 'click hover',
                  }"
                >
                  info
                </v-icon>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 class="pt-4">
            <v-card>
              <v-card-title class="flex justify-start">
                <h4><span v-html="selectedMenu.breadcrumb"></span></h4>
              </v-card-title>
              <div v-if="menu == 'main'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout row xs12 class="pa-2" wrap justify-space-around>
                      <v-flex xs12 md12 lg6 class="mt-4">
                        <div @click="menu = 'wifi'" class="selectablecard mx-2">
                          <div class="selectablecard__icon">
                            <v-icon large>wifi</v-icon>
                          </div>
                          <div class="selectablecard__title">
                            <span class="selectablecard__title__text">WiFi Settings</span>
                          </div>
                          <div class="selectablecard__description">
                            <span class="selectablecard__description__text">
                              Add, remove or clear device stored WiFi settings
                            </span>
                          </div>
                        </div>
                      </v-flex>
                      <v-flex xs12 md12 lg6 class="mt-4">
                        <div @click="menu = 'power'" class="selectablecard mx-2">
                          <div class="selectablecard__icon">
                            <v-icon large>power</v-icon>
                          </div>
                          <div class="selectablecard__title">
                            <span class="selectablecard__title__text">Change Power Settings</span>
                          </div>
                          <div class="selectablecard__description">
                            <span class="selectablecard__description__text">
                              Change device power settings into always off, always on, smart mode or bluetooth mode
                            </span>
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="disconnectBleDevice()">DISCONNECT</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'wifi'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout row xs12 class="pa-2" wrap justify-space-around>
                      <v-flex xs12 md12 lg4 class="mt-4">
                        <div @click="menu = 'wifi_add'" class="selectablecard mx-2">
                          <div class="selectablecard__icon">
                            <v-icon large>add_circle_outline</v-icon>
                          </div>
                          <div class="selectablecard__title">
                            <span class="selectablecard__title__text">Add New WiFi Network</span>
                          </div>
                          <div class="selectablecard__description">
                            <span class="selectablecard__description__text">Add new WiFi network to currently connected device</span>
                          </div>
                        </div>
                      </v-flex>
                      <v-flex xs12 md12 lg4 class="mt-4">
                        <div
                          @click="
                            menu = 'wifi_stored';
                            getStoredWifiNetworks();
                          "
                          class="selectablecard mx-2"
                        >
                          <div class="selectablecard__icon">
                            <v-icon large>storage</v-icon>
                          </div>
                          <div class="selectablecard__title">
                            <span class="selectablecard__title__text">Stored WiFi Networks</span>
                          </div>
                          <div class="selectablecard__description">
                            <span class="selectablecard__description__text">View currently connected device stored WiFi networks</span>
                          </div>
                        </div>
                      </v-flex>
                      <v-flex xs12 md12 lg4 class="mt-4">
                        <div
                          @click="
                            menu = 'wifi_clear';
                            getStoredWifiNetworks();
                          "
                          class="selectablecard mx-2"
                        >
                          <div class="selectablecard__icon">
                            <v-icon large>delete</v-icon>
                          </div>
                          <div class="selectablecard__title">
                            <span class="selectablecard__title__text">Clear All Stored WiFi Networks</span>
                          </div>
                          <div class="selectablecard__description">
                            <span class="selectablecard__description__text">Permanently delete and remove all stored WiFi networks on the connected device</span>
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="menu = 'main'">Back</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'wifi_add'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-center>
                      <v-flex xs12 lg6 sm9>
                        <AddNetworkForm
                          @cancelled="menu = 'wifi'"
                          @submitted="(inputs) => addWifiNetwork(inputs)"
                          cancel-button-text="Back"
                          cancel-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light"
                          confirm-button-class="v-btn--outline v-btn--depressed v-btn--round v-btn--small theme--light green--text"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
              </div>
              <div v-if="menu == 'wifi_added'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-center>
                      <v-flex xs12 sm10 md8 lg5>
                        <div class="text-xs-start mb-2">
                          <h3 class="success--text">WiFi Network Added</h3>
                        </div>

                        <p class="text-xs-left">Plugzio device will now automatically attempt to connect to the network</p>
                        <p class="text-xs-left mt-4">List of stored networks:</p>
                        <div class="text-xs-left">
                          <ul>
                            <li v-for="(wifi, index) in storedWifiNetworks" :key="index">
                              <span v-if="wifi">
                                <strong>{{ wifi[5] }}</strong>
                                <span class="ml-2 grey--text">
                                  ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                                  <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="menu = 'wifi'">Back To WiFi Settings</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat="flat" @click="menu = 'main'">Main Menu</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'wifi_stored'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-center>
                      <v-flex xs12 sm10 md8 lg5>
                        <p class="text-xs-left mt-4">List of stored networks:</p>
                        <div class="text-xs-left">
                          <ul>
                            <li v-for="(wifi, index) in storedWifiNetworks" :key="index">
                              <span v-if="wifi">
                                <strong>{{ wifi[5] }}</strong>
                                <span class="ml-2 grey--text">
                                  ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                                  <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex></v-card-text
                >
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="menu = 'wifi'">Back To WiFi Settings</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat="flat" @click="menu = 'main'">Main Menu</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'wifi_clear'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-center>
                      <v-flex xs12 sm10 md8 lg5>
                        <div class="text-xs-start mb-2">
                          <h3 class="error--text">Warning</h3>
                        </div>

                        <p class="text-xs-justify">
                          This will permanently delete and remove all stored WiFi networks on the device. Once the networks are deleted, the device will no longer attempt to
                          connect to the deleted networks on the next reboot or power cycle. This action cannot be undone and networks will have to be manually added back to the
                          device.
                        </p>
                        <p class="text-xs-left mt-4">List of stored networks:</p>
                        <div class="text-xs-left">
                          <ul>
                            <li v-for="(wifi, index) in storedWifiNetworks" :key="index">
                              <span v-if="wifi">
                                <strong>{{ wifi[5] }}</strong>
                                <span class="ml-2 grey--text">
                                  ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                                  <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <p class="text-xs-left mt-4">Are you sure to proceed?</p>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="menu = 'wifi'">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat="flat" @click="clearAllStoredWifiNetworks()">Confirm</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'wifi_cleared'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-center>
                      <v-flex xs12 sm10 md8 lg5>
                        <div class="text-xs-start mb-2">
                          <h3 class="success--text">Success</h3>
                        </div>

                        <p class="text-xs-justify">
                          All WiFi networks have been cleared from the device. Only default WiFi network credentials remain. List of stored networks:
                        </p>
                        <div class="text-xs-left">
                          <ul>
                            <li v-for="(wifi, index) in storedWifiNetworks" :key="index">
                              <span v-if="wifi">
                                <strong>{{ wifi[5] }}</strong>
                                <span class="ml-2 grey--text">
                                  ({{ wifi[4] === 0 ? "Unsecured" : "Secured" }})
                                  <span v-if="wifi[5] === 'Plugzio'">[Default]</span>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <p class="text-xs-justify mt-4">
                          The default network listed above is programmed onto the device for easy and convenient set up. This allows you to configure a router or even hot spot your
                          phone to allow the device to connect to the internet. For security purposes, we do not advise using the default network's credentials as a permanent
                          connection to the internet. To set up a secure connection, select the following option:
                        </p>
                        <v-btn class="plugzio-button mb-2 mt-4 wifi-setup-button" block outline round @click="menu = 'wifi_add'">
                          Add New WiFi Network
                        </v-btn>
                        <p class="text-xs-justify mt-4">
                          Default credentials are:<br />
                          <span class="ml-4 mt-2">Name: <strong>Plugzio</strong></span> <br />
                          <span class="ml-4">Password: <strong>WelcomePlugzio</strong></span>
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn color="gray" flat="flat" @click="menu = 'wifi'">Back To WiFi Settings</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat="flat" @click="menu = 'main'">Main Menu</v-btn>
                </v-card-actions>
              </div>
              <div v-if="menu == 'power'">
                <v-card-text>
                  <v-flex xs12>
                    <v-layout xs12 justify-start class="mt-4">
                      <p class="font-weight-bold mb-4">Setting {{ `Plugzio-${ble.connectedDeviceId}` }} power setting to:</p>
                    </v-layout>
                    <v-layout justify-center>
                      <v-flex xs11>
                        <v-select
                          class="mt-3"
                          v-model="inputMode"
                          :items="plugModes"
                          item-text="name"
                          item-value="code"
                          label="Power Settings"
                          outline
                          required
                          :hint="selectedMode.description"
                          persistent-hint
                        ></v-select
                      ></v-flex>
                    </v-layout>
                  </v-flex>
                </v-card-text>
                <v-card-actions class="pa-3 flex flex-wrap">
                  <v-btn
                    color="gray"
                    flat="flat"
                    @click="
                      menu = 'main';
                      inputMode = 2;
                    "
                    >Back</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn color="green" flat @click="updateDeviceMode()">Confirm</v-btn>
                </v-card-actions>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm9 lg6 v-else>
        <v-card>
          <v-card-title class="flex">
            <h3>Connect To Device Via Bluetooth</h3>
          </v-card-title>
          <div>
            <v-card-text>
              <p class="font-weight-bold my-4 justify-center">
                Enter your Device ID to connect to and configure your device by Bluetooth.
              </p>

              <v-text-field
                v-model="deviceId"
                @keyup.enter="connectBleDevice()"
                label="Enter device # here"
                outline
                prepend-inner-icon="power"
                hint="Characters 'A' to 'K' and '0' to '9' only"
                persistent-hint
                autocomplete="false"
                required
                :error="!!error"
                class="mb-0"
                ref="deviceIdInput"
              >
                <template v-slot:append>
                  <v-progress-circular v-if="!!loading" size="24" color="info" indeterminate></v-progress-circular>
                  <qr-scanner v-else @captured="qrCaptured" />
                </template>
              </v-text-field>

              <p class="text-xs-left mt-4">
                <img src="./../../assets/warning-owner.png" width="16px" class="mr-2" style="position:relative;top:3px;" />
                <span>Please make sure the device is powered on. When connecting by Bluetooth, please remain within close proximity of the device.</span>
              </p>
            </v-card-text>
            <v-card-actions class="pa-3 flex justify-end">
              <v-btn class="plugzio-button" outline round @click="connectBleDevice()" :loading="!!loading">CONNECT</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import mixin from "@/mixins";
import bleHandler from "@/mixins/ble/modules/bleHandler";
import QrScanner from "@/components/modals/QrScanner";
import AddNetworkForm from "@/components/forms/AddNetworkForm.vue";
export default {
  name: "BleConfiguration",
  mixins: [mixin, bleHandler],
  components: {
    QrScanner,
    AddNetworkForm,
  },
  data() {
    return {
      deviceId: null,
      deviceDisconnected: null,
      disconnectedDeviceId: null,
      error: null,
      loading: false,
      menu: "main",
      menus: [
        {
          slug: "main",
          breadcrumb: "Main Menu",
        },
        {
          slug: "wifi",
          breadcrumb: "Main Menu &gt; WiFi Settings",
        },
        {
          slug: "wifi_add",
          breadcrumb: "Main Menu &gt; WiFi Settings &gt; Add New WiFi Network",
        },
        {
          slug: "wifi_added",
          breadcrumb: "Main Menu &gt; WiFi Settings &gt; Add New WiFi Network",
        },
        {
          slug: "wifi_stored",
          breadcrumb: "Main Menu &gt; WiFi Settings &gt; Stored WiFi Network",
        },
        {
          slug: "wifi_clear",
          breadcrumb: "Main Menu &gt; WiFi Settings &gt; Clear WiFi Networks",
        },
        {
          slug: "wifi_cleared",
          breadcrumb: "Main Menu &gt; WiFi Settings &gt; Clear WiFi Networks",
        },
        {
          slug: "power",
          breadcrumb: "Main Menu &gt; Change Power Settings",
        },
      ],
      inputMode: 2,
      plugModes: [
        {
          code: 0,
          name: "Always Off",
          description: "Disables your device. Plugging in doesn't do anything!",
        },
        {
          code: 1,
          name: "Always On",
          description: "Just like a regular device. Anyone can plug in!",
        },
        {
          code: 2,
          name: "Smart",
          description: "Allows you to control access, set billing rates, monitor your device's status and power consumption.",
        },
        // {
        //   code: 3,
        //   name: "Bluetooth",
        //   description: "Control your device through your phone without a network connection.",
        // },
      ],
      visiblePasswordInputs: {
        password: false,
        confirm_password: false,
      },
      storedWifiNetworks: [],
    };
  },
  computed: {
    ownerBleCredentials() {
      return this.$store.getters.ownerBleCredentials;
    },
    ownerBleSessions() {
      return this.$store.getters.ownerBleSessions;
    },
    selectedMenu() {
      let selectedMenu = this.menus.filter((menu) => menu.slug == this.menu)[0];
      if (typeof selectedMenu == "undefined") {
        selectedMenu = this.menus.filter((menu) => menu.slug == "main")[0];
      }
      return selectedMenu;
    },
    selectedMode() {
      if (this.inputMode !== null) {
        return this.plugModes.find((o) => {
          return o.code === this.inputMode;
        });
      }
      return { code: null, description: null, name: null };
    },
  },
  watch: {
    deviceId: function(value) {
      if (value && !value.includes("aryawifi")) {
        this.$nextTick(() => {
          this.error = null;
          if (!value.match(/-/)) {
            this.deviceId = value.toUpperCase().replace(/[^0-9a-k]/gi, "");
          }
        });
      } else {
        this.error = null;
      }
    },
    menu: function(value) {
      switch (value) {
        case "wifi_add":
          this.wifi = {
            ssid: "",
            security: null,
            encryption: 0,
            channel: null,
            password: "",
            confirm_password: "",
            checkbox: true,
          };
          break;
        default:
          break;
      }
    },
  },
  methods: {
    addWifiNetwork(inputs) {
      try {
        this.loading = "Adding New WiFi Network...";
        this.resetBleCommandAndResponseVariables();
        this.sendBleCommand(this.bleCommands.WIFI_ADD_NETWORK, {
          id: this.bleToken.ownerId,
          realm: 1,
          data: {
            ssid: inputs.ssid,
            password: String(inputs.password),
            securityType: inputs.security,
            securityCipher: inputs.encryption,
            channel: inputs.channel,
          },
        }).then((response) => {
          this.handleSuccessfullBleCommand(`Response of WIFI_ADD_NETWORK : ${JSON.stringify(response)}`);
          this.menu = "wifi_added";
          this.loading = null;
          this.storedWifiNetworks = response.data;
        });
      } catch (error) {
        this.onBleError("Something went wrong when sending command to device, please refresh the site and retry");
      }
    },
    async connectBleDevice() {
      if (!this.deviceId) {
        this.$nextTick(() => this.$refs.deviceIdInput.focus());
        return;
      }
      let get_token_error = false;
      this.error = null;
      this.loading = "Connecting To Device...";
      if (!this.bleToken || !this.bleConnection.forceConnectManually) {
        await this.getBleToken(this.deviceId, "owner", "__plugzio_storage_owner_ble_credentials__")
          .then((token) => {
            this.bleToken = token;
            this.$store.dispatch("setOwnerBleCredential", { deviceId: this.deviceId, credential: token });
          })
          .catch((error) => {
            console.error(error);
            get_token_error = true;
            this.error = error;
            this.loading = null;
          });
      }
      if (get_token_error) return;

      this.connectToBleDevice(this.deviceId)
        .then((response) => {
          this.ble.connectedDevice.addEventListener("gattserverdisconnected", _onDisconnectedBleDevice);

          console.log(this.ble.connectedDevice.name);
          try {
            const deviceNameMatch = this.ble.connectedDevice.name != `Plugzio-${this.deviceId}`;

            this.loading = "Verifying Connection...";
            this.resetBleCommandAndResponseVariables();
            this.sendBleCommand(this.bleCommands.VERIFY_CONNECTION, { id: this.bleToken.ownerId, realm: 1 })
              .then((response) => {
                this.handleSuccessfullBleCommand(`Response of VERIFY_CONNECTION : ${JSON.stringify(response)}`);

                this.loading = "Setting Device Time...";
                return this.sendBleCommand(this.bleCommands.DEVICE_SET_SYSTEM_TIME, {
                  id: this.bleToken.ownerId,
                  realm: 1,
                  data: { time: Math.floor(new Date().getTime() / 1000) },
                }).then((response) => this.handleSuccessfullBleCommand(`Response of DEVICE_SET_SYSTEM_TIME : ${JSON.stringify(response)}`));
              })
              .then(() => {
                var deleteDeviceSessionsPromise = this.sendBleCommand(this.bleCommands.BLE_CLEAR_SESSIONS, {
                  id: this.bleToken.ownerId,
                  realm: 1,
                  data: { time: this.bleToken.deleteTimestamp },
                }).then((response) => this.handleSuccessfullBleCommand(`Response of BLE_CLEAR_SESSIONS : ${JSON.stringify(response)}`));

                if (deviceNameMatch) {
                  this.loading = "Setting Device Name...";
                  return new Promise((resolve, reject) => {
                    this.sendBleCommand(this.bleCommands.DEVICE_SET_EXTERNAL_ID, {
                      id: this.bleToken.ownerId,
                      realm: 1,
                      data: { externalID: this.deviceId },
                    })
                      .then((res) => {
                        this.handleSuccessfullBleCommand(`Response of DEVICE_SET_EXTERNAL_ID : ${JSON.stringify(res)}`);

                        this.loading = "Clearing Device Sessions...";
                        resolve(deleteDeviceSessionsPromise);
                      })
                      .catch((error) => reject(error));
                  });
                }
                this.loading = "Clearing Device Sessions...";
                return deleteDeviceSessionsPromise;
              })
              .then(() => {
                console.log("goes here");
                this.loading = "Getting Sessions Data...";
                return this.sendBleCommand(this.bleCommands.BLE_GET_SESSION_LIST, {
                  id: this.bleToken.ownerId,
                  realm: 1,
                });
              })
              .then((response) => {
                this.handleSuccessfullBleCommand(`Response of BLE_GET_SESSION_LIST : ${JSON.stringify(response)}`);

                return new Promise((resolve, reject) => {
                  if (response.data.length == 0) {
                    resolve();
                    return;
                  }
                  this.sendBleCommand(this.bleCommands.BLE_GET_SESSION_DATA, {
                    id: this.bleToken.ownerId,
                    realm: 1,
                    data: { index: response.data.length - 1 },
                  })
                    .then((response) => {
                      resolve({
                        chunks: JSON.parse(this.bleResponse.response, function(key, value) {
                          if (key == "chunks") return JSON.stringify(value);
                          return value;
                        }).chunks,
                        signature: response.signature,
                      });
                      this.handleSuccessfullBleCommand(`Response of BLE_GET_SESSION_DATA : ${JSON.stringify(response)}`);
                    })
                    .catch((error) => reject(error));
                });
              })
              .then((sessionData) => {
                this.loading = "Uploading sessions to Cloud...";
                return new Promise((resolve, reject) => {
                  if (!sessionData) {
                    resolve();
                    return;
                  }
                  console.log(sessionData);
                  this.$store.dispatch("setOwnerBleSessions", { sessions: sessionData, deviceId: this.deviceId });

                  this.$nextTick(async () => {
                    if (navigator.onLine) {
                      for (const owner in this.ownerBleSessions) {
                        const devices = this.ownerBleSessions[owner];
                        for (const device in devices) {
                          const session = devices[device];
                          if (session.hasOwnProperty("chunks") && session.hasOwnProperty("signature")) {
                            await this.uploadOwnerBleSessions(device, session.signature, session.chunks)
                              .then(async (response) => {
                                this.$store.dispatch("setOwnerBleSessions", { sessions: null, deviceId: device });
                                await this.getBleToken(this.deviceId, "owner", "__plugzio_storage_owner_ble_credentials__")
                                  .then((token) => {
                                    if (this.deviceId == device) {
                                      this.bleToken = token;
                                      this.$store.dispatch("setOwnerBleCredential", {
                                        deviceId: this.deviceId,
                                        credential: token,
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });
                              })
                              .catch((error) => {
                                console.log(`Upload session data for device ${device} failed.`);
                              });
                          }
                        }
                      }
                    }
                    resolve();
                  });
                });
              })
              .then(() => (this.loading = null))
              .catch((error) => this.onBleError(error));
          } catch (error) {
            console.error(error);
            this.onBleError("Something went wrong when sending command to device, please refresh the site and retry");
          }
        })
        .catch((error) => {
          console.error(error);
          this.loading = null;
          this.error = error;
        });

      var _this = this;

      function _onDisconnectedBleDevice() {
        _this.ble.connectedDevice.removeEventListener("gattserverdisconnected", _onDisconnectedBleDevice);
        _this.ble.connectedDevice = null;
        _this.ble.connectedDeviceId = null;
        _this.bleToken = null;
        _this.loading = null;
        _this.error = null;
        _this.deviceId = null;
        if (!_this.deviceDisconnected) _this.deviceDisconnected = "unintentional";

        // current handling of intentional disconnect
        if (_this.deviceDisconnected == "intentional") {
          _this.menu = "main";
          _this.deviceDisconnected = null;
        }
      }
    },
    clearAllStoredWifiNetworks() {
      try {
        this.loading = "Clearing Stored WiFi Network...";
        this.resetBleCommandAndResponseVariables();
        this.sendBleCommand(this.bleCommands.WIFI_CLEAR_STORED_NETWORKS, { id: this.bleToken.ownerId, realm: 1 })
          .then((response) => {
            this.handleSuccessfullBleCommand(`Response of WIFI_CLEAR_STORED_NETWORKS : ${JSON.stringify(response)}`);
            this.loading = null;
            this.menu = "wifi_cleared";
            this.storedWifiNetworks = response.data;
          })
          .catch((error) => this.onBleError(error));
      } catch (error) {
        this.onBleError("Something went wrong when sending command to device, please refresh the site and retry");
      }
    },
    disconnectBleDevice() {
      this.deviceDisconnected = "intentional";
      this.disconnectedDeviceId = this.ble.connectedDeviceId;
      this.loading = "Diconnecting...";
      this.ble.connectedDevice.gatt.disconnect();
    },
    getStoredWifiNetworks() {
      try {
        this.loading = "Getting Stored WiFi Network...";
        this.resetBleCommandAndResponseVariables();
        this.sendBleCommand(this.bleCommands.WIFI_GET_STORED_NETWORKS, { id: this.bleToken.ownerId, realm: 1 })
          .then((response) => {
            this.handleSuccessfullBleCommand(`Response of WIFI_GET_STORED_NETWORKS : ${JSON.stringify(response)}`);
            this.loading = null;
            this.storedWifiNetworks = response.data;
          })
          .catch((error) => this.onBleError(error));
      } catch (error) {
        this.onBleError("Something went wrong when sending command to device, please refresh the site and retry");
      }
    },
    handleSuccessfullBleCommand(message) {
      console.log(message);
      this.resetBleCommandAndResponseVariables();
    },
    onBleError(error) {
      console.error(error);
      this.loading = null;
      this.error = error;
    },
    qrCaptured(evt) {
      if (!evt || evt == "clear") {
        this.deviceId = null;
      }

      if (!evt.match(/my.plugz.io\/activate-plug\?external_id=/)) {
        this.error = "Non Plugzio QR Code detected";
      }
      let code = evt.split("=");
      this.deviceId = code[1];
    },
    resetBleCommandAndResponseVariables() {
      this.resetBleCommandVariables();
      this.resetBleResponseVariables();
    },
    updateDeviceMode() {
      // this.bleApiChangeDeviceMode(this.ble.connectedDeviceId, this.inputMode)
      //     .then((response) => console.log(response))
      //     .catch((error) => (this.error = error))
      //     .finally(() => (this.loading = null));
      try {
        this.loading = "Updating BLE Power Setting...";
        this.resetBleCommandAndResponseVariables();
        this.sendBleCommand(this.bleCommands.DEVICE_SET_MODE, {
          id: this.bleToken.ownerId,
          realm: 1,
          data: {
            mode: this.inputMode,
          },
        })
          .then((response) => {
            this.handleSuccessfullBleCommand(`Response of DEVICE_SET_MODE : ${JSON.stringify(response)}`);
            this.loading = null;
          })
          .catch((error) => this.onBleError(error));
      } catch (error) {
        this.onBleError("Something went wrong when sending command to device, please refresh the site and retry");
      }
    },
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("identifier")) {
      this.deviceId = this.$route.query.identifier;
      if (this.$route.query.hasOwnProperty("autoStart") && this.$route.query.autoStart) this.connectBleDevice();
    }
  },
};
</script>

<style lang="scss" scoped>
.bluetoothconnected {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 8px;
  align-items: center;
  flex-direction: row;
  border-radius: 50px;
  border: 1px solid #43a047;
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);

  .bluetoothconnected__icon .v-icon {
    color: #287aa9 !important;
    caret-color: #287aa9 !important;
  }
  &__text {
    font-size: 16px;
    margin-left: 4px;
  }
}
.selectablecard {
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
  cursor: pointer;
  &.selected,
  &:hover {
    color: #43a047;
    background-color: white;
    box-shadow: 0 2px 10px 1px rgba(57, 73, 76, 0.4), 0 1px 2px rgba(57, 73, 76, 0.25);
    .selectablecard__icon .v-icon {
      color: #4caf50 !important;
      caret-color: #4caf50 !important;
    }
  }
  &.selected {
    border: 2px solid #43a047;
  }
  &__icon {
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    margin-top: 4px;
  }
  &__description {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.addwificheckbox {
  .v-input {
    &__slot {
      align-items: start;
    }
  }
}
</style>
