<template>
  <v-container fluid class="fill-height text-xs-center full-inner-height">
    <!-- <v-layout row wrap>
      <v-flex xs12>
        <h1 class="d-inline-block">Advance Settings</h1>
      </v-flex>
    </v-layout> -->
    <v-layout align-center justify-center>
      <empty :data="false">
        <!-- <h3>Advance Settings</h3> -->
        <h1>Coming Soon</h1>
      </empty>
    </v-layout>
  </v-container>
</template>

<script>
import mixin from './../../mixins'
import Empty from './../../containers/Empty'

export default {
  mixins: [mixin],
  components: {
    Empty
  }
}
</script>
