<template>
  <v-container fluid :class="{ 'fill-height text-xs-center full-inner-height': empty || !withData }">
    <v-layout row wrap v-if="withData === true">
      <v-flex xs3 sm3 class="text-xs-left page-toolbar">
        <h1 class="d-inline-block mt-2" style="font-size: 25px;">{{ role == "admin" && !!managerview ? `${managerview.owner_username} > ` : "" }} Service Subscription</h1>
      </v-flex>
      <v-flex xs9 sm9 class="text-xs-right page-toolbar">
        <div class="d-inline-block">
          <v-btn
            small
            class="plugzio-button create-button mx-0 px-3 mb-1 mt-1 mr-1 py-1"
            style="min-width: auto; height: 30%; border-radius: 30px;"
            round
            outline
            :disabled="role === 'admin' || !allowAccess.CONFIGURE"
            @click="$router.push('/owner/configure-subscription')"
          >
            <v-icon size="18" class="center ma-0" key="icon">settings</v-icon>
            <span 
              class="ml-1 hidden-md-and-down" 
              key="description"
            >
              CONFIGURE DEVICE SUBSCRIPTIONS
            </span>
          </v-btn>
        </div>
        <DateRange 
          :is-owner="role == 'owner'" 
          class="date-range" 
          style="margin: 5px;"
          :with-timezone="role == 'admin'" 
          timezone-text-position="left" 
        />
      </v-flex>
      <v-flex sm6 xs12>
        <v-card class="mx-2 my-3">
          <v-card-title @click.stop="show.iconColors = !show.iconColors" class="pointer">
            <h3>
              Icon Colors
            </h3>
            <v-spacer></v-spacer>
            <v-btn flat small icon class="ma-0" @click.stop="show.iconColors = !show.iconColors">
              <v-icon v-if="show.iconColors">keyboard_arrow_up</v-icon>
              <v-icon v-else>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-card-title>
          <v-list class="legend pa-0 ma-0" v-if="show.iconColors">
            <v-list-tile>
              <v-list-tile-avatar class="legent-icon-avatar">
                <v-icon size="30" color="#00acc1">power</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  Always On
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
            <v-list-tile>
              <v-list-tile-avatar class="legent-icon-avatar">
                <v-icon size="30" color="#f44336">power</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  Always Off
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
            <v-list-tile>
              <v-list-tile-avatar class="legent-icon-avatar">
                <v-icon size="30" color="#FFA500">power</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>
                  Smart
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex sm6 xs12>
        <v-card class="mx-2 my-3">
          <v-card-title @click.stop="show.rowColors = !show.rowColors" class="pointer">
            <h3>
              Row Colors
            </h3>
            <v-spacer></v-spacer>
            <v-btn flat small icon class="ma-0" @click.stop="show.rowColors = !show.rowColors">
              <v-icon v-if="show.rowColors">keyboard_arrow_up</v-icon>
              <v-icon v-else>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-card-title>
          <v-list class="legend pa-0 ma-0" v-if="show.rowColors">
            <v-list-tile>
              <v-list-tile-avatar>
                <v-sheet color="white" class="legend-row" style="border: 1px solid rgb(204, 204, 204) !important" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="ml-2">
                  > 30 days to expiry time
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
            <v-list-tile>
              <v-list-tile-avatar>
                <v-sheet color="rgba(255, 247, 0, .35)" class="legend-row" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="ml-2">
                  &lt;= 30 days to expiry time
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
            <v-list-tile>
              <v-list-tile-avatar>
                <v-sheet color="rgba(244, 67, 54, .35)" class="legend-row" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="ml-2">
                  &lt;= 7 days to expiry time
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
            <v-list-tile>
              <v-list-tile-avatar>
                <v-sheet color="rgba(50, 50, 50, .25)" class="legend-row" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="ml-2">
                  Device has been disabled
                  <v-icon
                    class="tooltip-icon legend-tooltip"
                    v-tooltip="{
                      content: tooltips.expiry,
                      trigger: 'click hover',
                    }"
                    >info</v-icon
                  >
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>

      <!-- Add Search Bar -->
      <v-flex sm4 xs5>
        <v-text-field
          max-width="300"
          class="session-search mb-0 ml-2"
          v-model.lazy="search"
          :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
          autocomplete="false"
          :disabled="$store.state.loading"
          prepend-icon="search"
        >
          <template v-if="enableRegexSearch" v-slot:prepend-inner>
            <v-flex>
              <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
            </v-flex>
          </template>
          <template v-slot:append>
            <v-flex align-self-center class="search-append-div">
              <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

              <v-icon
                @click="enableRegexSearch = !enableRegexSearch"
                class="tooltip-icon"
                :color="enableRegexSearch ? `blue lighten-2` : ''"
                v-tooltip="{
                  content: tooltips.search,
                  trigger: 'hover',
                }"
                >settings_applications</v-icon
              >
            </v-flex>
          </template>
        </v-text-field>
      </v-flex>
      <v-flex sm8 xs7 class="text-xs-right pt-3 pr-2" v-if="$store.state.loading">
        <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
      </v-flex>

      <!-- Filters -->
      <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
        <!-- Filters Power Settings -->
        <v-menu offset-y>
          <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Power Settings: &nbsp;
              <strong>{{ filter.status.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">settings</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.status.property === item.property }"
              v-for="(item, index) in filter.statuses"
              :key="index"
              @click="filter.status = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>

        <!-- Filters Hidden Devices -->
        <v-menu offset-y>
          <v-btn slot="activator" value="hideDevice" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Show Hidden Devices: &nbsp;
              <strong>{{ filter.showHiddenDevice ? "Yes" : "No" }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">hide_source</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.showHiddenDevice === item.property }"
              v-for="(item, index) in filter.hiddenOptions"
              :key="index"
              @click="filter.showHiddenDevice = item.property"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-flex>
      <!-- End -->


      <v-flex xs12>
        <div class="elevation-1 ma-2">
          <div class="v-table__overflow">
            <table class="v-datatable v-table" :class="[darkTheme ? 'theme--dark' : 'theme--light']">
              <thead>
                <tr>
                  <th width="20px" class="column text-xs-left"></th>
                  <th
                    class="column text-xs-left sortable"
                    :class="{ active: plugSort.col === 'identifier', asc: plugSort.asc, desc: !plugSort.asc }"
                    @click="sortCol('identifier')"
                    
                  >
                    Device ID
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  </th>
                  <th
                    class="column text-xs-left sortable"
                    :class="{ active: plugSort.col === 'description', asc: plugSort.asc, desc: !plugSort.asc }"
                    @click="sortCol('description')"
                  >
                    Description
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  </th>
                  <th
                    class="column text-xs-left sortable"
                    :class="{ active: plugSort.col === 'remaining_raw', asc: plugSort.asc, desc: !plugSort.asc }"
                    @click="sortCol('remaining_raw')"
                  >
                    Remaining Days
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  </th>
                  <th
                    class="column text-xs-left sortable"
                    :class="{ active: plugSort.col === 'start_timestamp', asc: plugSort.asc, desc: !plugSort.asc }"
                    @click="sortCol('start_timestamp')"
                  >
                    Registration Date
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  </th>
                  <th
                    class="column text-xs-left p-relative sortable"
                    :class="{ active: plugSort.col === 'expiry_timestamp', asc: plugSort.asc, desc: !plugSort.asc }"
                    @click="sortCol('expiry_timestamp')"
                  >
                    Expiry Date
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                    <v-icon
                      v-if="toolTipExpiry"
                      class="tooltip-icon expiry-tooltip"
                      v-tooltip="{
                        content: toolTipExpiry,
                        trigger: 'click hover',
                      }"
                      >info</v-icon
                    >
                  </th>
                  <th class="column text-xs-left sortable" :class="{ active: plugSort.col === 'auto', asc: plugSort.asc, desc: !plugSort.asc }" @click="sortCol('auto')">
                    Auto Payment
                    <i aria-hidden="true" class="v-icon material-icons arrow-icon theme--light" style="font-size: 16px;">arrow_upward</i>
                  </th>


                </tr>
                <tr class="v-datatable__progress" v-if="loading">
                  <th colspan="8" class="column">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-linear" style="height: 2px;">
                      <div class="v-progress-linear__background primary" style="height: 2px; opacity: 0.3; width: 100%;"></div>
                      <div class="v-progress-linear__bar">
                        <div class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active">
                          <div class="v-progress-linear__bar__indeterminate long primary"></div>
                          <div class="v-progress-linear__bar__indeterminate short primary"></div>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>  
              <template v-for="(plug, index) in combinedPlugs">
                <tbody
                  v-if="filter.showHiddenDevice || !hiddenDevices.includes(plug.identifier)"
                  :key="index"
                > 
                <tr :class="plug.expired" @click.stop.prevent="plug.expanded = !plug.expanded">
                  <td><v-icon class="mr-0 log-icon" :color="plug.color">power</v-icon></td>
                  <td>{{ plug.identifier }}
                    <span v-if="hiddenDevices.includes(plug.identifier)">
                      <strong>(Hidden)</strong>
                    </span>
                  </td>
                  <td>{{ plug.description }}</td>
                  <td class="no-wrap">{{ plug.remaining }}</td>
                  <td class="no-wrap">{{ plug.start }}</td>
                  <td class="no-wrap">{{ plug.expiry }}</td>
                  <td>
                    <span :class="plug.auto_class">{{ plug.auto }}</span>
                  </td>
                  <td v-if="role === 'owner' || role === 'admin'" class="text-xs-right no-wrap">
                    <v-btn
                      v-if="plug.state === 0"
                      @click="showReactivateDialog"
                      :disabled="role === 'admin' || !allowAccess.REACTIVATE"
                      flat
                      small
                      class="billing-button mr-0"
                    >
                      Reactivate
                    </v-btn>
                    <v-btn
                      v-else
                      @click.stop="addTime(plug)"
                      flat
                      small
                      class="billing-button mr-0"
                      :disabled="role === 'admin' || !allowAccess.EXTEND"
                    >
                      Extend Subscription
                    </v-btn>
                    <v-btn
                      @click.stop="setAuto(plug)"
                      flat
                      small
                      class="billing-button mr-0 ml-2"
                      :disabled="role === 'admin' || !allowAccess.SET_AUTO_PAYMENT"
                    >
                      Set Auto Payment
                    </v-btn>
                    <v-btn
                      style="min-width: 30px"
                      @click.stop="plug.expanded = !plug.expanded"
                      flat
                      small
                      class="billing-button mr-0 ml-2"
                    >
                      <v-icon small v-if="plug.expanded">keyboard_arrow_up</v-icon>
                      <v-icon small v-else>keyboard_arrow_down</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="role === 'owner' || role === 'admin'" v-show="plug.expanded">
                  <td colspan="8" class="pa-0">
                    <div class="log-container pa-4">
                      <BillingLogs v-if="plug.id" :plug="plug" :show="plug.expanded" :time-format="timeFormat" />
                    </div>
                  </td>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-layout align-center justify-center v-if="!withData">
      <empty :data="withData" :width="750">
        <zero-billing :mode-route="role == 'admin' ? 'admin-managerview-' : roleSetting.routePrefix" />
      </empty>
    </v-layout>
    
    <v-dialog v-model="addTimeDialog" max-width="360">
      <v-card>
        <v-card-title>
          <h3>{{ addTimeData.summary ? "Purchase Summary" : "Add Subscription Time" }}</h3>
        </v-card-title>
        <v-alert :value="error" type="error">
          {{ error }}
        </v-alert>
        <v-card-text class="text-xs-center" v-if="addTimeDialogLoading"> <v-progress-circular class="loading-circle my-4" size="60" indeterminate color="orange"/></v-card-text>
        <template v-else>
          <v-card-text v-show="!addTimeData.summary">
            <v-form ref="addSubscriptionForm" @submit="processAddSubscription()" v-model="valid">
              <h3 class="mb-3">Device ID: {{ addTimeData.plugIdentifier }}</h3>
              <h3 class="d-inline-block">Pay From:</h3>
                <v-tooltip
                  bottom  
                  :offset-y="-10"                 
                >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="tooltip-icon mb-0 pull-right"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                  <span class="tooltip-content">
                    {{ tooltips.dropdown }} 
                  </span>
                </v-tooltip>
              <v-autocomplete
                v-model="addTimeData.walletId"
                :items="specificWallets"
                item-text="name"
                item-value="id"
                label="Wallet"
                outline
                autocomplete="false"
                required
                :rules="[rules.required]"
                :error="!!error"
                :disabled="loading"
                hint="Payments in USD or CAD currencies only"
                persistent-hint
                class="mt-3"
              ></v-autocomplete>
              <div class="mt-3 mb-4 text-xs-center">
                <strong>$ {{ addTimeData.dataplan && addTimeData.dataplan.cost_cad > 0 ? formatCost(addTimeData.dataplan.cost_cad) : "0.00" }} CAD / 30 Days</strong> or
                <strong>$ {{ addTimeData.dataplan && addTimeData.dataplan.cost_usd > 0 ? formatCost(addTimeData.dataplan.cost_usd) : "0.00" }} USD / 30 Days</strong>
              </div>
              <h3 class="d-inline-block">Duration:</h3>
              <v-text-field
                v-model="addTimeData.months"
                type="number"
                solo
                autocomplete="false"
                min="1"
                required
                @keydown="monthFilter"
                step="1"
                :rules="[rules.oneOrMore]"
                :error="!!error"
                :disabled="loading"
                persistent-hint
                suffix=" x 30 Days"
                class="mt-3 solo-button"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-text v-if="addTimeData.summary">
            <p class="bill-summary">
              Device ID: <strong>{{ addTimeData.plugIdentifier }}</strong
              ><br />
              Wallet: <strong>{{ addTimeData.summary.wallet }}</strong
              ><br />
              Wallet Balance: <strong>$ {{ addTimeData.summary.balance | numeral("0,0.00") }} </strong><br />
              Currency: <strong>{{ addTimeData.summary.currency }}</strong>
              <br />
              Current Expiry Date: <strong>{{ $moment(addTimeData.currentExpiry * 1000).format("DD-MMM-YYYY") }}</strong
              ><br />
              Purchased Days: <strong>{{ addTimeData.summary.days | numeral("0,0") }} Days</strong><br />
              <!-- Bonus Time: <strong>{{ addTimeData.summary.bonus }} Days</strong><br /> -->
              New Expiry Date: <strong>{{ addTimeData.summary.readable }}</strong>
            </p>
            <h3>
              Total:
              <span :class="addTimeData.summary.insufficient ? 'error--text' : 'green--text'">
                $ {{ addTimeData.summary.total | numeral("0,0.00") }} {{ addTimeData.summary.currency }}
              </span>
            </h3>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="addTimeData.summary"
              color="gray"
              flat="flat"
              @click="
                addTimeData.summary = null;
                error = false;
              "
            >
              Back
            </v-btn>
            <v-btn v-else color="gray" flat="flat" @click="addTimeDialog = false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              :loading="busy"
              :disabled="addTimeData.summary && addTimeData.summary.insufficient"
              @click.stop.prevent="processAddSubscription()"
            >
              {{ addTimeData.summary ? "CONFIRM" : "NEXT" }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="setAutoDialog" max-width="360">
      <v-card>
        <v-card-title
          ><h3>{{ autoPaymentData.summary ? "Auto Payment" : "Set Auto Payment" }}</h3></v-card-title
        >
        <v-alert :value="error" type="error">
          {{ error }}
        </v-alert>
        <v-card-text class="text-xs-center" v-if="autoDialogLoading"> <v-progress-circular class="loading-circle my-4" size="60" indeterminate color="orange"/></v-card-text>
        <template v-else>
          <v-card-text v-show="!autoPaymentData.summary">
            <v-form ref="setAutoForm" @submit="processAutoPayment()" v-model="valid">
              <h3 class="mb-3">Device ID: {{ autoPaymentData.plugIdentifier }}</h3>
              <h3 class="d-inline-block">Pay From:</h3>
              <v-tooltip
                  bottom  
                  :offset-y="-10"                 
                >
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="tooltip-icon mb-0 pull-right"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                  <span class="tooltip-content">
                    {{ tooltips.dropdown }} 
                  </span>
                </v-tooltip>
              <v-autocomplete
                v-if="autoPaymentData.disabled"
                :items="specificWallets"
                item-text="name"
                item-value="id"
                label="Wallet"
                outline
                autocomplete="false"
                disabled
                hint="Payments in USD or CAD currencies only"
                persistent-hint
                class="mt-3"
              />
              <v-autocomplete
                v-else
                v-model="autoPaymentData.walletId"
                :items="specificWallets"
                item-text="name"
                item-value="id"
                label="Wallet"
                outline
                autocomplete="false"
                required
                :rules="[rules.required]"
                :error="!!error"
                :disabled="loading || autoPaymentData.disabled"
                hint="Payments in USD or CAD currencies only"
                persistent-hint
                class="mt-3"
              ></v-autocomplete>
            </v-form>
            <v-divider class="mt-4 mb-0"></v-divider>
            <div class="wallet-dialog-separator">
              OR
            </div>

            <v-checkbox class="mt-4" v-model="autoPaymentData.disabled" required color="success" hide-details>
              <template v-slot:label>
                Disable Auto-payment
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-text v-if="autoPaymentData.summary">
            <template v-if="autoPaymentData.disabled">
              <p class="bill-summary">
                Device ID: <strong>{{ autoPaymentData.plugIdentifier }}</strong
                ><br />
                Wallet: <strong>None</strong><br />
                Auto Payment: <strong>Disabled</strong><br />
                Expiry: <strong>{{ autoPaymentData.expiry }}</strong>
              </p>
              <div>
                <ul class="summary-note">
                  <div class="text-xs-center">
                    <v-icon color="orange" size="32">warning</v-icon>
                  </div>
                  <li v-for="(note, index) in expiryNotes" :key="index">{{ note }}</li>
                </ul>
              </div>
            </template>

            <template v-else>
              <p class="bill-summary">
                Device ID: <strong>{{ autoPaymentData.plugIdentifier }}</strong
                ><br />
                Wallet: <strong>{{ autoPaymentData.summary.wallet }}</strong
                ><br />
                Wallet Balance: <strong>$ {{ autoPaymentData.summary.balance | numeral("0,0.00") }} </strong><br />
                Currency: <strong>{{ autoPaymentData.summary.currency }}</strong
                ><br />
                Amount: <strong>$ {{ autoPaymentData.summary.amount | numeral("0,0.00") }} / Month</strong><br />
              </p>
              <div class="summary-note"><p>Subscription will automatically be renewed when the device has &lt;30 days to expiry.</p></div>
            </template>
            
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="autoPaymentData.summary"
              color="gray"
              flat="flat"
              @click="
                autoPaymentData.summary = null;
                error = false;
              "
            >
              Back
            </v-btn>
            <v-btn v-else color="gray" flat="flat" @click="setAutoDialog = false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="autoPaymentData.remaining > 0" color="green darken-1" flat="flat" :loading="busy" @click.stop.prevent="processAutoPayment()">
              {{ autoPaymentData.summary ? "CONFIRM" : "NEXT" }}
            </v-btn>
            <v-btn v-else color="green darken-1" flat="flat" :loading="busy" @click.stop.prevent="showReactivateDialog">
              REACTIVATE
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <ReactivateDialog :show="reactivateDialog" @close="reactivateDialog = false;" :loading="loading" /> 
  </v-container>
</template>

<script>
import mixin from "@/mixins";
import sort from "@/mixins/sort";
import { mapState, mapGetters } from "vuex";
import Api from "@/library/apis/Api";
import PlugHelpers from "@/library/helpers/PlugHelpers";
import BillingLogs from "@/components/BillingLogs.vue";
import DateRange from "@/components/modals/DateRange.vue";
import Empty from "@/containers/Empty.vue";
import ZeroBilling from "@/components/zero/Billing.vue"
import OwnerConfigureSubscription from "./OwnerConfigureSubscription.vue";
import { OwnerProxyHelpers } from '@/library/helpers';
import ReactivateDialog from "@/components/modals/ReactivateDialog.vue";

export default {
  mixins: [mixin, sort],
  components: {
    BillingLogs,
    DateRange,
    Empty,
    ZeroBilling,
    OwnerConfigureSubscription,
    ReactivateDialog
},
  data() {
    return {
      search: null,
      enableRegexSearch: false,
      rawPlugs: [],
      plugs: [],
      rawWallets: [],
      addTimeDialog: false,
      addTimeDialogLoading: false,
      reactivateDialog: false, 
      setAutoDialog: false,
      autoDialogLoading: false,
      timeFormat: "DD-MMM-YYYY hh:mm:ss A",
      filter: { 
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Smart",
            property: 2,
          },
          {
            label: "Always On",
            property: 1,
          },
          {
            label: "Always Off",
            property: 0,
          },
        ],
        showHiddenDevice: false,
        hiddenOptions: [
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No", 
            property: false,
          },
        ],
      },
      headers: [
        { text: "", value: "", sortable: false, width: "20px" },
        { text: "Device ID", value: "identifier" },
        { text: "Description", value: "description" },
        { text: "Registration Date", value: "start" },
        { text: "Expiry Date", value: "expiry" },
        { text: "Auto Payment", value: "auto" },
        { text: "", value: "", sortable: false },
      ],
      addTimeData: {
        months: null,
        plugId: null,
        plugIdentifier: null,
        walletId: null,
        chargeAmount: 0,
        currentExpiry: null,
        summary: null,
        dataplan: null,
      },
      autoPaymentData: {
        plugId: null,
        walletId: null,
        disabled: false,
        expiry: null,
        summary: null,
        remaining: 0,
      },
      plugSort: {
        col: "identifier",
        asc: true,
      },
      show: {
        iconColors: true,
        rowColors: true,
      },
      withData: false,
      error: false,
      valid: true,
      busy: false,
      expiryNotes: [
        "Devices will automatically be disabled anytime after the subscription has expired.",
        "There is a reactivation fee for reactivating disabled devices.",
        "Reactivating disable devices will take between 3 - 5 business days.",
        "Disabled devices will retain their initial mode right before they were disabled.",
        "You will no longer be able to control disabled devices in the Device Management page.",
        'No one will be able to access/use disabled devices unless the device was previously set to the "Always On" power setting.',
      ],
    };
  },
  computed: {
    ...mapState({
      managerview: (state) => state.Admin.managerview,
      adminActiveView: (state) => state.Admin.activeView, 
      timezone: (state) => state.Global.timezone,
      preferences: state => state.Global.preferences || {},
      role: (state) => state.Auth.role,
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices",
    }),
    ...mapGetters("Wallet", {
      specificWallets: "specificWallets",
    }),
    storedTime() {
      return this.$store.getters.storedTime;
    },
    plugsCount() {
      return this.$store.getters.plugsCount;
    },
    noPlugs() {
      return this.plugsCount === 0 && this.plugsCount !== null;
    },
    wallets() {
      let specificWallets = this.rawWallets;
      let newWallets = [];
      specificWallets.forEach((o) => {
        if (o.currency === "USD" || o.currency === "CAD") {
          o.name = o.description + " (" + o.currency + ")";
          o.rate = o.currency === "USD" ? 9 : 10;
          newWallets.push(o);
        }
      });
      return newWallets;
    },
    toolTipExpiry() {
      let notes = '<ul class="mt-2">';
      this.expiryNotes.forEach((note) => {
        notes += `<li class="mb-2">${note}</li>`;
      });
      return notes + "</ul>";
    },
    tooltips() {
      return {
        expiry: this.toolTipExpiry,
        extend: `One time payment to add subscription time to an device`,
        autopayment: `Associate a wallet with your device and to automatically<br>renew the device's service on a monthly basis.`,
        dropdown: `Payments in USD or CAD currencies only`,
        search: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`
      };
    },
    combinedPlugs() { 
      let plugs = this.plugs || [];
      const formatted_filters = {
        status: this.filter.status.property == "all" ? "all" : parseInt(this.filter.status.property),
        search: this.search,
        enableRegexSearch: this.enableRegexSearch,
      };
      
      return PlugHelpers.filter(plugs, formatted_filters).sort(this.compareValues(this.plugSort.col, this.plugSort.asc ? "asc" : "desc"));
    },
    loading() {
      return this.$store.state.loading;
    },
    empty() {
      return this.withData === false;
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    },
    allowAccess() {
      return {
        CONFIGURE: OwnerProxyHelpers.isHaveAccessToFeature("SERVICE_SUBSCRIPTION_CONFIGURE"),
        EXTEND: OwnerProxyHelpers.isHaveAccessToFeature("SERVICE_SUBSCRIPTION_EXTEND"),
        SET_AUTO_PAYMENT: OwnerProxyHelpers.isHaveAccessToFeature("SERVICE_SUBSCRIPTION_SET_AUTO_PAYMENT"),
        REACTIVATE: OwnerProxyHelpers.isHaveAccessToFeature("SERVICE_SUBSCRIPTION_REACTIVATE"),
      }
    }
  },
  watch: {
    addTimeDialog() {
      this.$nextTick(() => {
        this.error = false;
        this.addTimeData.summary = null;
        this.addTimeData.dataplan = null;
        this.addTimeDialogLoading = true;
        this.getDataplan(this.addTimeData.plugIdentifier)
          .then((data) => (this.addTimeData.dataplan = data))
          .finally(() => (this.addTimeDialogLoading = false));
        if (!this.addTimeDialog) {
          this.$refs.addSubscriptionForm.reset();
        }
      });
    },
    setAutoDialog() {
      this.$nextTick(() => {
        this.error = false;
        if (this.autoPaymentData.remaining <= 0)
          this.error = "The device has been disabled due to an expired subscription. Please reactivate the device in order to configure auto payments.";

        this.autoPaymentData.summary = null;
        if (!this.setAutoDialog) {
          this.$refs.setAutoForm.reset();
        }
      });
    },
    timezone() {
      this.plugs = this.formatPlugs(JSON.parse(JSON.stringify(this.rawPlugs)));
    },
  },
  methods: {
    showReactivateDialog() { 
      this.reactivateDialog = true; 
      this.setAutoDialog = false; 
    },
    loadPlugs() {
      this.$store.dispatch("loading", true);
      const formData = this.managerview ? { ownerUsername: this.managerview.owner_username } : {};
      const apiPrefix = this.adminActiveView == "managerview" ? "admin/owner" : this.adminActiveView == "userview" ? "admin/user" : this.role;
      Promise.all([Api.plugs(apiPrefix, formData), Api.wallets(apiPrefix, formData)])
        .then(([plugs, wallets]) => {
          this.rawPlugs = plugs || [];
          this.rawPlugs.length > 0 ? (this.withData = true) : (this.withData = false);
          this.plugs = this.formatPlugs(JSON.parse(JSON.stringify(this.rawPlugs)));
          this.rawWallets = wallets || [];
          if (this.rawPlugs.length > 0) this.$store.dispatch("setPlugClosestExpirationTimestamp", this.rawPlugs);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.dispatch("loading", false);
        });
    },
    formatPlugs(plugs) {
      return plugs.map((o) => {
        let start = this.$moment.unix(o.start_timestamp).tz(this.timezone);
        let expiry = this.$moment.unix(o.expiry_timestamp).tz(this.timezone);
        let today = this.$moment();
        let diff = expiry.diff(today, "days");
        o.auto = o.payment_wallet_id ? "ON" : "OFF";
        o.auto_class = o.payment_wallet_id ? "green--text" : "orange--text";
        o.start = start.format(this.timeFormat);
        o.expiry = expiry.format(this.timeFormat);
        o.diff = diff;

        if (o.state === 0) {
          o.expired = "bill-expired";
          o.remaining = "Disabled";
          o.remaining_raw = 0;
        } else {
          if (diff <= 7) {
            o.expired = "bill-warning";
          } else if (diff <= 30) {
            o.expired = "bill-notify";
          } else {
            o.expired = "";
          }
          o.remaining_raw = o.diff;
          o.remaining = o.diff < 1 ? `${Math.abs(o.diff)} Days Expired` : o.diff + " Days";
        }

        if (o.plugmode === 0) {
          o.color = "#f44336";
        } else if (o.plugmode === 1) {
          o.color = "#00acc1";
        } else {
          o.color = "#FFA500";
        }

        o.logs = [];
        o.expanded = false;
        o.busy = false;

        return o;
      });
    },
    addTime(item) {
      this.addTimeData.plugId = item.id;
      this.addTimeData.plugIdentifier = item.identifier;
      this.addTimeData.currentExpiry = item.expiry_timestamp;
      this.addTimeDialog = true;
    },
    setAuto(item) {
      this.autoPaymentData.plugId = item.id;
      this.autoPaymentData.plugIdentifier = item.identifier;
      this.autoPaymentData.walletId = item.payment_wallet_id;
      this.autoPaymentData.disabled = !item.payment_wallet_id;
      this.autoPaymentData.expiry = item.expiry;
      this.autoPaymentData.remaining = item.remaining_raw;
      this.setAutoDialog = true;
    },
    getDataplan(plugId) {
      return Api.plugDataplanConfig("owner", { plugIdentifier: plugId }).catch((error) => {
        console.log(error.message);
        this.error = error.message;
      });
    },
    processAddSubscription() {
      if (this.addTimeData.summary) {
        this.submit();
      } else {
        if (this.$refs.addSubscriptionForm.validate()) {
          let months = this.addTimeData.months;
          let days = months * 30;
          // let bonus = Math.floor(days / 360) * 30;
          let expiry = this.$moment(this.addTimeData.currentExpiry * 1000)
            .add(days, "days")
            // .add(days + bonus, "days")
            .unix();
          let readable = this.$moment.unix(expiry).format("DD-MMM-YYYY");

          let wallet = this.rawWallets.find((o) => {
            return o.id === this.addTimeData.walletId;
          });

          const rate = wallet.currency == "USD" ? this.addTimeData.dataplan.cost_usd : this.addTimeData.dataplan.cost_cad;
          let total = months * rate;
          let insufficient = total > wallet.balance;

          if (insufficient) {
            this.error = "Wallet has insufficient funds. Please top up the selected wallet in the 'Wallets' page or go back and select a new wallet";
          }

          this.addTimeData.chargeAmount = total;
          this.addTimeData.summary = {
            currency: wallet.currency,
            wallet: wallet.description,
            balance: wallet.balance,
            currentExpiry: this.$moment(this.addTimeData.currentExpiry * 1000).format("DD-MMM-YYYY"),
            days,
            months,
            // bonus,
            expiry,
            readable,
            total,
            insufficient,
          };
          console.log(this.addTimeData);
        }
      }
    },
    processAutoPayment() {
      if (this.autoPaymentData.summary) {
        this.submit(true);
      } else {
        if (this.autoPaymentData.disabled) {
          this.autoPaymentData.walletId = null;
          this.autoPaymentData.summary = {
            wallet: null,
          };
        } else {
          if (this.$refs.setAutoForm.validate()) {
            this.autoDialogLoading = true;
            this.getDataplan(this.autoPaymentData.plugIdentifier)
              .then((data) => {
                let wallet = this.rawWallets.find((o) => {
                  return o.id === this.autoPaymentData.walletId;
                });

                const dataplan = data;
                this.autoPaymentData.summary = {
                  currency: wallet.currency,
                  wallet: wallet.description,
                  balance: wallet.balance,
                  amount: wallet.currency == "USD" ? dataplan.cost_usd : dataplan.cost_cad,
                };
              })
              .finally(() => (this.autoDialogLoading = false));
          }
        }
      }
    },
    sortCol(col) {
      this.plugSort.col = col;
      this.plugSort.asc = !this.plugSort.asc;

      console.log(this.plugSort);
    },
    submit(auto = false) {
      this.busy = true;
      const request = auto
        ? Api.plugSetPaymentWallet("owner", { plugId: this.autoPaymentData.plugId, walletId: this.autoPaymentData.walletId })
        : Api.ownerPlugRenewService(this.addTimeData);
      request
        .then((data) => {
          this.loadPlugs();
          this.$store.dispatch("snackbar", { message: data });
          this.setAutoDialog = false;
          this.addTimeDialog = false;
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.busy = false;
        });
    },
    monthFilter(e) {
      const key = e.key;
      if (key === ".") return e.preventDefault();
    }
  },
  mounted() {
    if (this.role == "admin" && !this.managerview) return this.$router.push("/admin/managerview");
    this.loadPlugs();
  }
};
</script>

<style lang="sass" scoped>
.log-container
  background: #f2f2f2

.log-icon
  color: rgba(0,0,0,.4)

.bill-warning
  background: rgba(244, 67, 54, .35) !important

.bill-expired
  background: rgba(50, 50, 50, .25) !important

.bill-notify
  background: rgba(255, 247, 0, .35) !important

.billing-button
  min-width: 128px
  border-radius: 4px
  padding: 2px 8px
  background: rgba(50,50,50, .05) !important
  font-size: .78em !important
  text-transform: uppercase
  color: #333333

.bill-summary
  line-height: 1.8em

.header-info
  position: absolute
  right: 113px
  top: 16px
  width: 130px

.wallet-dialog-separator
  width: 50px
  margin: -10px auto
  background: #fff
  text-align: center

.summary-note
  font-size: .9em
  padding: 1em
  background: #f8f8f8
  li
    margin: .5em .5em .5em 2em

.expiry-tooltip
  position: absolute !important
  left: 106px
  top: 16px
  font-size: 24px !important
  opacity: 1 !important

.theme--light.v-table tbody tr:not(:last-child)
  border-bottom: 1px solid rgba(0,0,0,0.05)

.theme--light.v-table tbody:not(:last-child)
  border-bottom: 1px solid rgba(0,0,0,0.05)

.legend .v-list__tile__title
  font-size: .9em !important

.legend-row
  width: 100%
  height: 24px

.legend-tooltip
  margin: 0 0 -3px 15px

.legent-icon-avatar
  min-width: 48px
  .v-avatar
    width: 36px !important
    margin-right: 0 !important

.v-datatable thead th.column.sortable.active.desc .v-icon
  -webkit-transform: none
  transform: none

.v-datatable thead th.column.sortable.active.desc .arrow-icon
  -webkit-transform: rotate(-180deg)
  transform: rotate(-180deg)
</style>
