<template>
  <v-container fluid :class="{ 'fill-height text-xs-center full-inner-height': accessPlans.length == 0 || loading }">
    <div v-if="accessPlans.length > 0">
      <v-layout row wrap justify-start>
        <v-flex xs6 sm6 class="text-xs-left page-toolbar">
          <h1 class="d-inline-block ma-0 mt-2 ml-2" style="font-size: 25px;">{{ role == "admin" && !!managerview ? `${managerview.owner_username} > ` : "" }} Payment Plans</h1>
        </v-flex>
        <v-flex xs6 sm6 class="text-xs-right page-toolbar"> 
          <div class="ml-3 mt-2 d-inline-block">
            <v-btn 
              small 
              dark 
              color="green" 
              class="plugzio-button create-button mx-0 px-3 mb-1 mt-2"
              style="min-width: auto; height: 30%; border-radius: 30px;"
              round
              outline 
              :disabled="!allowedAccess.CREATE"
              @click="redirectToCreateAccessPlan"
            >
              <v-icon size="20" class="ma-0" key="icon">note_add</v-icon>
              <span 
                class="ml-1 hidden-md-and-down" 
                key="description"
              >
                CREATE PLAN
              </span>
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row class="mt-0 mb-0">
        <v-flex sm4 xs5>
          <v-text-field
            max-width="300"
            class="session-search mb-0 ml-2 border-none"
            v-model.lazy="filters.search"
            :placeholder="!filters.enableRegexSearch ? 'Search' : 'Search RegEx'"
            autocomplete="false"
            :disabled="loading"
            prepend-icon="search"
          >
            <template v-if="filters.enableRegexSearch" v-slot:prepend-inner>
              <v-flex>
                <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
              </v-flex>
            </template>
            <template v-slot:append>
              <v-flex align-self-center class="search-append-div">
                <i v-if="filters.enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

                <v-icon
                  @click="filters.enableRegexSearch = !filters.enableRegexSearch"
                  class="tooltip-icon"
                  :color="filters.enableRegexSearch ? `blue lighten-2` : ''"
                  v-tooltip="{
                    content: tooltip,
                    trigger: 'hover',
                  }"
                  >settings_applications</v-icon
                >
              </v-flex>
            </template>
          </v-text-field>
        </v-flex>
        <v-flex sm8 xs7 class="text-xs-right pt-3 pr-2" v-if="loading">
          <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
        </v-flex>
        <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
          <v-menu offset-y>
            <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
              <span class="hidden-sm-and-down">
                Plan Type: &nbsp;
                <strong>{{ filters.planType.label }}</strong>
              </span>
              <v-icon class="hidden-md-and-up" :class="{ 'success--text': filters.planType.property === 1, 'warning--text': filters.planType.property === 0 }">power</v-icon>
            </v-btn>
            <v-list hover>
              <v-list-tile
                class="pointer"
                :class="{ 'menu-active': filters.planType.property === item.property }"
                v-for="(item, index) in variables.planTypes"
                :key="index"
                @click="filters.planType = item"
              >
                <small>{{ item.label }}</small>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
              <span class="hidden-sm-and-down">
                Auto-Start: &nbsp;
                <strong>{{ filters.autostart.label }}</strong>
              </span>
              <v-icon class="hidden-md-and-up">settings</v-icon>
            </v-btn>
            <v-list hover>
              <v-list-tile
                class="pointer"
                :class="{ 'menu-active': filters.autostart.property === item.property }"
                v-for="(item, index) in variables.autostarts"
                :key="index"
                @click="filters.autostart = item"
              >
                <small>{{ item.label }}</small>
              </v-list-tile>
            </v-list>
          </v-menu>

          <v-menu v-if="role === 'owner'" offset-y>
            <v-btn slot="activator" value="hidePlans" small flat class="page-toolbar-btn">
              <span class="hidden-sm-and-down">
                Show Hidden Plans: &nbsp;
                <strong>{{ filters.showHiddenPlans ? "Yes" : "No" }}</strong>
              </span>
              <v-icon class="hidden-md-and-up">credit_card_off</v-icon>
            </v-btn>
            <v-list hover>
              <v-list-tile
                class="pointer"
                :class="{ 'menu-active': filters.showHiddenPlans === item.property }"
                v-for="(item, index) in filters.hiddenPlansOptions"
                :key="index"
                @click="filters.showHiddenPlans = item.property"
              >
                <small>{{ item.label }}</small>
              </v-list-tile>
            </v-list>
          </v-menu>


          <v-menu offset-y>
            <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
              <span class="hidden-sm-and-down">
                Sort: &nbsp;
                <strong>{{ sorts.by.label }}</strong>
              </span>
              <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
            </v-btn>
            <v-list hover>
              <v-list-tile
                class="pointer"
                :class="{ 'menu-active': sorts.by.property === item.property }"
                v-for="(item, index) in variables.sorts"
                :key="index"
                @click="sorts.by = item"
              >
                <small>{{ item.label }}</small>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-btn flat small class="page-toolbar-btn" @click="sorts.directionDesc = !sorts.directionDesc" :disabled="!sorts.by.property">
            <v-icon :class="{ 'rotate-icon': !sorts.directionDesc }">sort</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="mt-0 mb-2">
        <v-flex
          lg4
          md6
          xs12
          v-for="(plan, index) in filteredAccessPlans"
          :key="index"
        >
          <div class="pa-2" style="height:100%">
            <v-card
              class="access-plan-card"
              height="100%"
              :class="{
                'access-plan-card__hidden': hiddenPayments.includes(plan.id)
              }"
            >
              <v-card-title 
                class="card-tile-header access-plan-title"
              >
                <h3>{{ plan.name }}</h3>
                <v-spacer></v-spacer>
                
                <!-- <v-icon
                  v-if="role == 'owner'"
                  small
                  color="#fff"
                  class="pointer"
                  @click="$router.push({ name: 'owner-payment-plan-form', params: { ...plan.params, planId: plan.id } })"
                > -->
                <v-icon
                  v-if="['admin', 'owner'].includes(role)"
                  small
                  color="#fff"
                  class="pointer" 
                  :disabled="!allowedAccess.EDIT"
                  @click="$router.push(getEditRoute(plan.id))"
                >
                  edit
                </v-icon>
              </v-card-title>
              <v-card-text v-if="!plan.params.timezone">
                <p>
                  Wallet: <strong>{{ plan.wallet.name }}</strong>
                </p>
                <p>
                  Activation Fee ({{ plan.wallet.currency }}): <strong>{{ formatCost(plan.params.startSessionCost, "") }}</strong>
                </p>
                <p>
                  Duration Rate ({{ plan.wallet.currency }} / h): <strong>{{ formatRate(plan.params.hRate, "", "") }}</strong>
                </p>
                <p>
                  Consumption Rate ({{ plan.wallet.currency }} / kWh): <strong>{{ formatRate(plan.params.whRate, "", "") }}</strong>
                </p>
                <p>
                  Auto-Start Sessions For Single User:
                  <strong>{{ typeof plan.params.isAutoStart == "undefined" || !plan.params.isAutoStart ? "Disabled" : "Enabled" }}</strong>
                </p>
              </v-card-text>
              <v-card-text v-else>
                <p>
                  Wallet: <strong>{{ plan.wallet.name }}</strong>
                </p>
                <p>
                  Timezone: <strong>{{ timezoneWithOffset(plan.params.timezone) }}</strong>
                </p>
                <p>
                  Auto-Start Sessions For Single User:
                  <strong>{{ typeof plan.params.isAutoStart == "undefined" || !plan.params.isAutoStart ? "Disabled" : "Enabled" }}</strong>
                </p>
                <v-expansion-panel class="mt-2">
                  <v-expansion-panel-content>
                    <template v-slot:header>
                      <p class="font-weight-medium">Billing Information</p>
                    </template>
                    <div class="pa-2">
                      <div class="px-2">
                        <template v-if="!plan.params.disabled">
                          <p>
                            By default I will bill users...
                          </p>
                          <div class="px-3">
                            <p>
                              Activation Fee ({{ plan.wallet.currency }}): <strong>{{ formatCost(plan.params.startSessionCost, "") }}</strong>
                            </p>
                            <p>
                              Duration Rate ({{ plan.wallet.currency }} / h): <strong>{{ formatRate(plan.params.hRate, "", "") }}</strong>
                            </p>
                            <p>
                              Consumption Rate ({{ plan.wallet.currency }} / kWh): <strong>{{ formatRate(plan.params.whRate, "", "") }}</strong>
                            </p>
                          </div>
                        </template>
                        <p v-else class="mb-0">Device is <strong>disabled</strong> by default</p>
                      </div>
                      <v-divider class="my-2"></v-divider>

                      <TimeBasedBillingInformation
                        :allow-add-new-rule="false"
                        plain
                        :show-actions="false"
                        :data="{ unless: plan.params.unless, selectedWalletCurrency: plan.wallet.currency }"
                      />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-card-text>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-layout align-center justify-center v-if="accessPlans.length == 0">
      <empty :data="loading">
        <zero-payment-plans />
          <v-btn 
            small 
            dark 
            color="green" 
            class="plugzio-button create-button mx-0 pr-3 pl-2 mb-1 mt-2 mr-4 px-3" 
            style="min-width: auto; height: 30%; border-radius: 30px;"
            round
            outline
            :disabled="!allowedAccess.CREATE"
            @click="redirectToCreateAccessPlan"
          >
              <v-icon size="20" style="margin: 0" key="icon">note_add</v-icon>
              <span class="ml-1" key="description">CREATE PLAN</span>
          </v-btn>
      </empty>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Api from "@library/apis/Api";
import mixin from "@/mixins";
import timezones from "@/data/timezones.json";
import AccessPlanHelpers from "@library/helpers/AccessPlanHelpers";
import ZeroPaymentPlans from "@/components/zero/PaymentPlans.vue";
import TimeBasedBillingInformation from "@/components/TimeBased/TimeBasedBillingInformation.vue";
import Empty from "@/containers/Empty.vue";
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: "AccessPlans",
  mixins: [mixin],
  components: {
    Empty,
    ZeroPaymentPlans,
    TimeBasedBillingInformation,
  },
  data() {
    return {
      filters: {
        autostart: {
          label: "All",
          property: "all",
        },
        enableRegexSearch: false,
        planType: {
          label: "All",
          property: "all",
        },
        search: null,
        showHiddenPlans: false, 
        hiddenPlansOptions: [ 
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No", 
            property: false,
          },
        ]
      },
      sorts: {
        by: {
          label: "Description",
          property: "description",
        },
        directionDesc: false,
      },
      tooltip: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`,
      variables: {
        autostarts: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Enabled",
            property: 1,
          },
          {
            label: "Disabled",
            property: 0,
          },
        ],
        planTypes: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Simple",
            property: "simple",
          },
          {
            label: "Conditional",
            property: "time-based",
          },
        ],
        sorts: [
          {
            label: "Description",
            property: "description",
          },
          {
            label: "Wallet",
            property: "wallet_name",
          },
          {
            label: "Timezone",
            property: "sort_variable_timezone",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      adminActiveView: (state) => state.Admin.activeView, 
      managerview: (state) => state.Admin.managerview,
      role: (state) => state.Auth.role,
      userview: (state) => state.Admin.userview,
      preferences: state => state.Global.preferences || {},  
    }),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    ...mapGetters(["accessPlans"]),
    ...mapGetters("PaymentPlan", { 
      visiblePayments: "visiblePayments",
      hiddenPayments: "hiddenPayments",
    }),
    filteredAccessPlans() {
      const accessPlans = this.filters.showHiddenPlans ? this.accessPlans : this.visiblePayments
      const formatted_filters = {
        planType: this.filters.planType.property == "all" ? "all" : this.filters.planType.property,
        autostart: this.filters.autostart.property == "all" ? "all" : parseInt(this.filters.autostart.property),
        sort_by: this.sorts.by.property,
        sort_direction_desc: this.sorts.directionDesc,
        search: this.filters.search,
        enableRegexSearch: this.filters.enableRegexSearch,
      };
      return AccessPlanHelpers.filter(accessPlans, formatted_filters);
    },
    allowedAccess() {
      return {
        CREATE: OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_CREATE"),
        EDIT: OwnerProxyHelpers.isHaveAccessToFeature("PAYMENT_PLAN_EDIT"),
      }
    }
  },
  methods: {
    timezoneWithOffset(timezone) {
      const selectedTimezone = timezones.find((tm) => tm.value == timezone);
      return selectedTimezone ? selectedTimezone.text : null;
    },
    redirectToCreateAccessPlan() {
      let target = "/owner/payment-plan-form"
      if (this.role === 'admin' && this.adminActiveView === 'managerview') target = "/admin/managerview-payment-plan-form"
      this.$router.push(target)
    },
    getEditRoute(id) {
      return this.role == "admin" ? `/admin/managerview-payment-plan-form/${id}` : `/owner/payment-plan-form/${id}`;
    },
  },
  mounted() {
    if (this.role == "admin" && !this.managerview) return this.$router.push("/admin/managerview");
    this.$store.dispatch("loading", true);
    
    let apiPrefix = this.role
    let formData = {}
    if (!!this.managerview) {
      apiPrefix = "admin/owner"
      formData = { ownerUsername: this.managerview.owner_username, planId: this.accessPlans.id }
    }
    if (!!this.userview) {
      apiPrefix = "admin/user"
      formData = { username: this.adminViewAsUser.username }
    }

    Promise.all([Api.plugPaymentAccessPlans(apiPrefix, formData), Api.wallets(apiPrefix, formData)])
      .then(([paymentPlans, wallets]) => {
        this.$store.dispatch("setPlans", paymentPlans);
        this.$store.dispatch("setWallets", wallets);
      })
      .catch((error) => console.log(error))
      .finally(() => this.$store.dispatch("loading", false));
  },
  created() {
    this.$store.dispatch("setPlans", []);
  }
};
</script>

<style lang="scss">
@import "./../../styles/colors.scss";
.access-plan-card {
  .v-expansion-panel {
    // box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    .v-expansion-panel__header {
      padding: 0.5rem;
      min-height: 24px;
    }
  }
  &__hidden {
    .access-plan-title {
      background-color: grey!important;
    }
  }
}
.access-plan-title {
  color: #ffffff;
  background-color: $user-color !important;
}

.access-plan-card {
  p {
    margin-bottom: 0;
  }
}
</style>
